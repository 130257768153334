import React from "react"
import { Link } from "gatsby"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import Layout from "../../components/de/layout"
//import Image from "../components/image"
import SEO from "../../components/de/seo"

import MainImg from "./../../images/main_bg.jpg"

import shape1 from "./../../images/dec/1.png"
import shape2 from "./../../images/dec/2.png"
import shape3 from "./../../images/dec/3.png"
import shape4 from "./../../images/dec/4.png"
import shape5 from "./../../images/dec/5.png"
import shape6 from "./../../images/dec/6.png"
import shape7 from "./../../images/dec/7.png"
import shape8 from "./../../images/dec/8.png"

import product1a from "./../../images/moonamar-kaltgepresstes-bio-schwarzkummelol-100-ml.png"

const CorokotovoUlje = () => (
  <Layout>
    <SEO
      title="MOONAMAR - KALTGEPRESSTES BIO-SCHWARZKÜMMELÖL 100 ML"
      description="MOONAMAR BIO-SCHWARZKÜMMELÖL - kaltgepresst und keiner Verarbeitung ausgesetzt, um die wohltuenden Substanzen zu schonen."
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body" />
    </section>

    <span className="_line" />

    <section id="_oProizvodu" className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="_slide">
              <img
                className="_img"
                src={product1a}
                alt="KALTGEPRESSTES BIO-SCHWARZKÜMMELÖL 100 ML"
                title="KALTGEPRESSTES BIO-SCHWARZKÜMMELÖL 100 ML"
              />
              <div className="_info">
                <div className="_inner">
                  <span>MOONAMAR</span>
                  <div className="title">BIO-Schwarzkümmelöl (100 ml)</div>
                  <div className="subtitle">Verpackungsgröße: 100 ml</div>
                  <div className="price">12,90 €</div>
                </div>
                <div className="_buttonShop">
                  <a href="https://www.mykotheke.at/produkte/komplementaere-produkte/bio-schwarzkuemmeloel-kaltgepresst-100-ml-moonamar-kopie" target="_blank">ZUR BESTELLUNG</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="_pageContent" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <Tabs>
              <TabList>
                <Tab>Produktbeschreibung</Tab>
                <Tab>Zutaten und Verwendung</Tab>
              </TabList>

              <TabPanel>
                <div className="content">
                  <p>
                    <strong>HERSTELLUNG</strong>: Kaltpressung ohne weitere
                    Verarbeitung <br /> <strong>ZUBEREITUNGSZUSÄTZE</strong>:
                    ohne zusätzliche Zutaten - 100% Schwarzkümmelöl <br />{" "}
                    <strong>QUALITÄT</strong>: BIO, Extra Vergine
                    <br /> <strong>SAMENTYP</strong>: Nigella Sativa&nbsp;{" "}
                    <br /> <strong>HERKUNFT DER SAMEN</strong>: Ägypten <br />{" "}
                    <strong>STABILITÄT</strong>: Schwarzkümmelöl ist aufgrund
                    seiner Zusammensetzung sehr stabil und beständig gegen
                    Keimvermehrung. Es wird nicht zum Kochen und Braten
                    empfohlen
                    <br />
                    <strong>LAGERUNG</strong>: Die geöffnete Flasche gut
                    verschlossen im Kühlschrank aufbewahren. Innerhalb von drei
                    Monaten aufbrauchen.
                  </p>
                  <p>
                    Einige Menschen vertragen nicht das Schwarzkümmelöl wegen
                    seines ungewöhnlichen Geschmacks. Aus diesem Grund kümmert
                    sich unser Team sowohl um die Qualität als auch um den
                    Geschmack. MOONAMAR-Schwarzkümmelöl hat einen milden
                    Geschmack dank eines speziell ausgewählten Saatguts mit
                    ägyptischer Ursprung und einem schonenden Pressvorgang.
                  </p>
                  <table>
                    <tbody>
                      <tr>
                        <td width="125">
                          <p>
                            <strong>Nährwerte</strong>
                          </p>
                        </td>
                        <td width="67">
                          <p>
                            <strong>pro 100 g</strong>
                          </p>
                        </td>
                        <td width="81">
                          <p>
                            <strong>pro 5 g</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Brennwert</p>
                        </td>
                        <td>
                          <p>
                            3.696 kJ
                            <br /> 899 kcal
                          </p>
                        </td>
                        <td>
                          <p>
                            184,8 kJ
                            <br /> 44,95 kcal
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Fett
                            <br />
                            <em>davon gesättigt</em>
                          </p>
                        </td>
                        <td>
                          <p>
                            99,9 g<br /> 15,9 g
                          </p>
                        </td>
                        <td>
                          <p>
                            4,99 g<br /> 0,79 g
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Kohlenhydrate
                            <br />
                            <em>davon Zucker</em>
                          </p>
                        </td>
                        <td>
                          <p>
                            &lt; 0,1 g<br /> &lt; 0,5 g
                          </p>
                        </td>
                        <td>
                          <p>
                            &lt; 0,005 g<br /> &lt; 0,025 g
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Eiweiß</p>
                        </td>
                        <td>
                          <p>0,1 g</p>
                        </td>
                        <td>
                          <p>0,005 g</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Salz</p>
                        </td>
                        <td>
                          <p>&lt; 0,0025 g</p>
                        </td>
                        <td>
                          <p>&lt; 0,000125 g</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <p>
                    Das MOONAMAR-Schwarzkümmelöl wird durch Kaltpressung
                    gewonnen und keiner weiteren Weiterverarbeitung ausgesetzt,
                    um die im Saatgut enthaltenen Wirkstoffe zu schonen.
                  </p>
                  <h3>Zutaten</h3>
                  <p>
                    Schwarzkümmelöl ist reich an verschiedenen Vitaminen,
                    Mineralstoffen, Proteinen, ungesättigten Fettsäuren,
                    Phytosterolen, verschiedenen ätherischen Ölen und Saponinen,
                    sowie Wirkstoffen, die nur in Schwarzkümmelöl vorkommen, wie
                    Nigellon, Thymochinon und Nigellin.
                  </p>
                  <h3>Anwendung:</h3>
                  <p>
                    Schwarzkümmelöl wird traditionell hauptsächlich zur
                    Regulierung des Immunsystems, sowohl als Unterstützung bei
                    diversen Allergiebehandlungen als auch zur Stärkung des
                    Immunsystems, verwendet. Um sich der positiven Effekte zu
                    erfreuen wird das Öl normalerweise mindestens 8 Wochen lang
                    zu sich genommen. Für diejenigen , die sich ungesund
                    ernähren ist Schwarzkümmelöl als Nahrungsergänzung
                    empfehlenswert.
                  </p>
                  <h3>Einnahmeempfehlung</h3>
                  <p>
                    <strong>Erwachsene:</strong> 2-3 mal täglich 1 EL möglichst
                    vor dem Essen oder zu den ausgewogenen Mahlzeiten. Als
                    Prävention wird 1 EL täglich vor dem Frühstuck eingenommen.
                    <br /> <strong>Kinder:</strong> 2-3 mal taglich 1 Teelöffel.
                    Als Prävention wird 1 TL täglich vor dem Frühstuck
                    eingenommen. Als Prävention wird 1 TL täglich vor dem
                    Frühstuck eingenommen.
                  </p>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default CorokotovoUlje
